<template>
    <div>
        <card-header title="Copy Flight" icon="fa-copy"/>

        <card-body v-if="flight">
            <card-list>
                <flight-list-item :flight="flight" :card="card" show-details/>
                <subheader-list-item title="Copy to Registration" icon="fa-copy"/>
                <container-list-item padded>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Registration" label-position="inside">
                                <b-select expanded v-model="copyRegistration">
                                    <option :value="r" v-for="r in registrations">{{ r.user.last_name }}, {{ r.user.first_name }} <span v-if="r.id === parseInt(props.registrationId)"> (Duplicate)</span></option>
                                </b-select>
                            </b-field>
                        </div>
                    </div>
                </container-list-item>
                <subheader-list-item icon="fa-align-left" title="Flight information"/>
                <container-list-item padded>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Applies To" label-position="inside">
                                <b-select expanded v-model="flight.applies_to" :disabled="!copyRegistration">
                                    <option v-if="copyRegistration" value="primary">{{copyRegistration.user.last_name}}, {{copyRegistration.user.first_name}} (Primary)</option>
                                    <option value="secondary" v-if="copyRegistration && copyRegistration.second_user">{{copyRegistration.second_user.last_name}}, {{copyRegistration.second_user.first_name}} (Secondary)</option>
                                    <option value="both" v-if="copyRegistration && copyRegistration.second_user">Both Participants</option>
                                </b-select>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-switch :true-value="1" :false-value="0" v-model="flight.primary_flight">This is an Primary Flight</b-switch>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Class" label-position="inside">
                                <b-select expanded v-model="flight.travel_class">
                                    <option value="first">First</option>
                                    <option value="business">Business</option>
                                    <option value="premium-economy">Premium Economy</option>
                                    <option value="economy">Economy</option>
                                    <option value="tbd">TBD</option>
                                </b-select>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Seat Preference" label-position="inside">
                                <b-select expanded v-model="flight.seat_preference">
                                    <option value="window">Window</option>
                                    <option value="aisle">Aisle</option>
                                    <option value="window-adjacent">Window & Adjacent</option>
                                    <option value="aisle-adjacent">Aisle & Adjacent</option>
                                    <option value="none">No Preference</option>
                                </b-select>
                            </b-field>
                        </div>
                        <div class="column is-3">
                            <b-field label="Seat" label-position="inside">
                                <b-input v-model="flight.seat"/>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Flight Cost (Hidden)" label-position="inside">
                                <b-input v-model="flight.cost"></b-input>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Reference Number" label-position="inside">
                                <b-input v-model="flight.reference_number"></b-input>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Additional Flight Notes" label-position="inside">
                                <b-input type="textarea" v-model="flight.flight_notes"></b-input>
                            </b-field>
                        </div>
                    </div>
                </container-list-item>
                <subheader-list-item icon="fa-tasks" title="Task"/>
                <container-list-item padded>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Status" label-position="inside">
                                <b-select expanded v-model="flight.task_status">
                                    <option value="planned">Planned</option>
                                    <option value="booked">Booked</option>
                                    <option value="confirmed">Confirmed</option>
                                    <option value="ticketed">Ticketed</option>
                                </b-select>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Payment" label-position="inside">
                                <b-select expanded v-model="flight.task_payment">
                                    <option value="unpaid">Unpaid</option>
                                    <option value="deposit">Deposit</option>
                                    <option value="paid">Paid</option>
                                    <option value="na">N/A</option>
                                </b-select>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Assignment" label-position="inside">
                                <b-select expanded v-model="flight.task_assigned_id">
                                    <option :value="a.id" v-for="a in assignees">{{a.first_name}} {{a.last_name}}</option>
                                </b-select>
                            </b-field>
                        </div>
                        <div class="column is-4">
                            <b-switch :true-value="1" :false-value="0" v-model="flight.task_complete">Complete/Not Tracked</b-switch>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Notes" label-position="inside">
                                <b-input type="textarea" v-model="flight.task_notes"/>
                            </b-field>
                        </div>
                    </div>
                </container-list-item>
            </card-list>

        </card-body>

        <card-footer class="pt-3 pb-3">
            <div class="columns">
                <div class="column has-text-right">
                    <b-button type="is-primary" size="is-small" :disabled="!copyRegistration" @click="submit"><i class="fas fa-copy mr-2"></i>Copy Flight</b-button>
                </div>
            </div>
        </card-footer>
    </div>
</template>

<script>
    import {client as http} from '../../http_client';
    import CardHeader from '../../TIER/components/CardHeader';
    import CardBody from '../../TIER/components/CardBody';
    import CardFooter from '../../TIER/components/CardFooter';
    import RegistrationListItem from "../../components/RegistrationListItem";

    import async from 'async';
    import CardList from "../../TIER/components/CardList";
    import CardToolbar from "../../TIER/components/CardToolbar.vue";
    import FlightListItem from "../../components/FlightListItem.vue";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem.vue";
    import ContainerListItem from "../../TIER/components/ContainerListItem.vue";
    import {mapGetters} from "vuex";

    export default {
        props: ['card', 'props'],
        components: {ContainerListItem, SubheaderListItem, FlightListItem, CardToolbar, CardList, RegistrationListItem, CardFooter, CardBody, CardHeader},
        data: function() {
            return {
                flight: null,
                registrations: null,
                registration: {trip: {}, user: {}},
                copyRegistration: null
            };
        },
        computed: {
            ...mapGetters(['assignees'])
        },
        methods: {
            loadFlight: function() {
                this.$emit('loading', true);

                async.series([
                    // Get flight
                    (cb) => {
                        http.get('/api/registrations/' + this.props.registrationId + '/flights/' + this.props.flightId, {force: true}).then(response => {
                            this.flight = response.data;
                            this.flight.task_notes = (this.flight.task_notes) ? this.flight.task_notes + ' (Flight copied from another registration.)' : '(Flight copied from another registration.)';
                            cb();
                        });
                    },
                    // Get registration
                    (cb) => {
                        http.get('/api/registrations/' + this.props.registrationId, {force: true}).then(response => {
                            this.registration = response.data;
                            cb();
                        });
                    },
                    // get trip registrations
                    (cb) => {
                        http.get('/api/trips/' + this.registration.trip_id + '/registrations/0/100', {force: true}).then(response => {
                            this.registrations = response.data.registrations;
                            this.registrations.sort((a, b) => {
                                return a.user.last_name.localeCompare(b.user.last_name);
                            });
                            cb();
                        });
                    }
                ], err => {
                    this.$emit('loading', false);
                });
            },
            submit() {
                this.$emit('loading', true);

                let payload = {
                    regId: this.copyRegistration.id,
                    ...this.flight
                }

                http.post('/api/registrations/' + this.props.registrationId + '/flights/' + this.props.flightId + '/copy', payload, {force: true}).then(response => {
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            }
        },
        mounted() {
            this.loadFlight();
        }
    };
</script>
